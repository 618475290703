import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ListPage from './pages/ListPage';
import AboutUsPage from './pages/AboutUsPage';
import AppAppBar from "./components/AppAppBar";
import Footer from './components/Footer';
import React from 'react';
import "./global.css"
import MarketingPage from './pages/MarketingPage';
import FAQ from './pages/FAQPage';
import ContactUsPage from './pages/ContactUsPage';
import TermsAndConditionsPage from './pages/TermsAndConditionsPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import SuccessPage from './pages/SuccessPage';
import CommunityPage from './pages/CommunityPage';

/**
 * The main application component.
 *
 * @returns {JSX.Element} The rendered application component.
 */
function App() {
  return (
    <Router>
      <AppAppBar />
        <Routes>
          <Route path="/" element={<MarketingPage/>} />
          <Route path="/about-us" element={<AboutUsPage />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/contact-us" element={<ContactUsPage/>} />
          <Route path="/terms-and-conditions" element={<TermsAndConditionsPage/>} />
          <Route path="/privacy-policy" element={<PrivacyPolicyPage/>} />
          <Route path="/mypassportbros-list" element={<ListPage/>} />
          <Route path="/discord-community" element={<CommunityPage/>} />
          <Route path={process.env.REACT_APP_SUCCESS_URL} element={<SuccessPage />} />
        </Routes>
        <Footer />
    </Router>
  );
}

export default App;
