import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import TablePagination from "@mui/material/TablePagination";
import CountryCard from "./CountryCard";

export default function CountryList({ completeCountryList }) {
  const [searchQuery, setSearchQuery] = useState("");
  const [sortCriteria, setSortCriteria] = useState("name-asc"); // Default sorting by name ascending
  const [page, setPage] = useState(0); // Pagination page
  const [countriesPerPage, setCountriesPerPage] = useState(8); // Number of countries per page

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSortChange = (event) => {
    setSortCriteria(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setCountriesPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page to 0 when changing rows per page
  };

  // Filter and sort countries
  const filteredCountries = completeCountryList
    .filter((completeCountry) =>
      completeCountry.countryData.name?.common
        .toLowerCase()
        .includes(searchQuery.toLowerCase())
    )
    .sort((a, b) => {
      if (sortCriteria === "name-asc") {
        return a.countryData.name.common.localeCompare(
          b.countryData.name.common
        );
      } else if (sortCriteria === "name-desc") {
        return b.countryData.name.common.localeCompare(
          a.countryData.name.common
        );
      } else if (sortCriteria === "safety-asc") {
        return (
          (a.safetyData?.advisory?.score || 0) -
          (b.safetyData?.advisory?.score || 0)
        );
      } else if (sortCriteria === "safety-desc") {
        return (
          (b.safetyData?.advisory?.score || 0) -
          (a.safetyData?.advisory?.score || 0)
        );
      }
      return 0;
    });

  // Paginate the filtered countries
  const paginatedCountries = filteredCountries.slice(
    page * countriesPerPage,
    page * countriesPerPage + countriesPerPage
  );

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        MyPassPortBros List
      </Typography>

      {/* Search Field */}
      <TextField
        label="Search by Country Name"
        variant="outlined"
        fullWidth
        margin="normal"
        value={searchQuery}
        onChange={handleSearchChange}
      />

      {/* Sort Dropdown */}
      <FormControl fullWidth sx={{ mb: 2, mt: 2 }}>
        <InputLabel>Sort By</InputLabel>
        <Select
          value={sortCriteria}
          label="Sort By"
          onChange={handleSortChange}
        >
          <MenuItem value="name-asc">Country Name (A-Z)</MenuItem>
          <MenuItem value="name-desc">Country Name (Z-A)</MenuItem>
          <MenuItem value="safety-asc">Safety Score (Low to High)</MenuItem>
          <MenuItem value="safety-desc">Safety Score (High to Low)</MenuItem>
        </Select>
      </FormControl>

      {/* Country Cards Grid */}
      <Grid container spacing={4} justifyContent="center">
        {paginatedCountries.map((completeCountry) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={3}
            key={completeCountry.countryData.cca3}
          >
            <CountryCard completeCountry={completeCountry} />
          </Grid>
        ))}
      </Grid>

      {/* Pagination Controls */}
      <TablePagination
        component="div"
        count={filteredCountries.length} // Total number of filtered countries
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={countriesPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[8, 16, 32]} // Customize options for items per page
      />
    </Box>
  );
}
